import React, { FC, useEffect, useRef } from "react";
import styled, { keyframes } from "styled-components";
import ScrollContainer from "react-indiana-drag-scroll";

import Card, { CardType } from "./Card";

import { toVWMobile, toVWDesktop, toREM } from "../../helpers/styles";
import { BREAKPOINTS } from "../../helpers/theme";
import { isMobile } from "react-device-detect";

export type CookItAtHomeProps = {
  title: string;
  cards: CardType[];
};

const CookItAtHome: FC<CookItAtHomeProps> = ({
  title,
  cards,
}: CookItAtHomeProps) => {
  const scrollRef = useRef<HTMLElement | null>();
  const pauseScroll = useRef(false);
  const shouldAddOffset = (index: number): boolean => {
    return index % 2 === 0;
  };

  if (!cards?.length) return null;

  useEffect(() => {
    const iId = setInterval(
      () => {
        try {
          if (
            scrollRef?.current?.scrollLeft >=
              (scrollRef?.current?.scrollWidth / 3) * 2 ||
            scrollRef?.current?.scrollLeft === 0
          ) {
            scrollRef.current.scrollLeft = scrollRef?.current?.scrollWidth / 3;
            return;
          }
          if (!pauseScroll.current)
            scrollRef.current.scrollLeft = scrollRef.current.scrollLeft + 1;
        } catch (e) {
          console.log(e);
        }
      },
      isMobile ? 30 : 10
    );
    return () => {
      clearInterval(iId);
    };
  }, []);

  return (
    <Container>
      {title && <Title>{title}</Title>}

      <StyledContainer
        innerRef={(r) => (scrollRef.current = r)}
        activationDistance={0}
      >
        {[...cards, ...cards, ...cards]?.map((card: CardType, i: number) => (
          <Card
            key={card?.id + i}
            {...card}
            withOffset={shouldAddOffset(i)}
            onHover={(hovered) => (pauseScroll.current = hovered)}
          />
        ))}
      </StyledContainer>
    </Container>
  );
};

const moveHorizontal = keyframes`
  from {
    transform: translateX(0)
  }

  to {
    transform: translateX(-100%)
  }
`;

const Container = styled.section`
  background-color: #ccb983;
`;

const Wrapper = styled.div<{ numberOfCards: number }>`
  display: flex;
  animation: ${moveHorizontal} ${(props) => props.numberOfCards * 2}s linear
    infinite;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    animation-duration: ${(props) => props.numberOfCards * 6}s;
  }
`;

const StyledContainer = styled(ScrollContainer)`
  padding: 0 0 ${toVWMobile(111)}vw;
  display: flex;
  direction: ltr;
  width: 100vw;
  gap: ${toVWMobile(20)}vw;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    gap: ${toVWDesktop(20)}vw;
    padding: 0 0 ${toVWDesktop(120)}vw;

    &:hover {
      cursor: grab;
    }

    &:hover ${Wrapper} {
      animation-play-state: paused;
    }
  }
`;

const Title = styled.h3`
  color: #012102;
  font-family: ${props => props.theme.fontFamily}, ${props => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(48)}rem;
  font-weight: 900;
  font-style: normal;
  letter-spacing: ${toREM(1.44)}rem;
  line-height: ${toREM(56)}rem;
  padding: ${toVWMobile(40)}vw ${toVWMobile(81)}vw ${toVWMobile(40)}vw
    ${toVWMobile(19)}vw;
  text-align: left;
  text-transform: uppercase;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    color: #033305;
    font-size: ${toREM(60)}rem;
    letter-spacing: ${toREM(1.8)}rem;
    line-height: ${toREM(64)}rem;
    padding: ${toVWDesktop(80)}vw 0 ${toVWDesktop(67)}vw;
    text-align: center;
  }
`;

export default CookItAtHome;
