import React, { FC } from "react";
import Collapsible from "react-collapsible";
import styled from "styled-components";
import SVG from "react-inlinesvg";

import Polygon from "../../assets/images/polygon.svg";
import { toVWMobile, toVWDesktop, toREM } from "../../helpers/styles";
import { BREAKPOINTS } from "../../helpers/theme";

export type CollapseMenuProps = {
  title: string;
  children: React.ReactNode;
};

const CollapseMenu: FC<CollapseMenuProps> = ({
  title = "",
  children,
}: CollapseMenuProps) => {
  return (
    <StyledCollapsible
      easing={"ease-in"}
      transitionTime={300}
      trigger={
        <CollapseTitleWrapper>
          <CollapseTitle>{title}</CollapseTitle>
          <Arrow src={Polygon} />
        </CollapseTitleWrapper>
      }
    >
      {children}
    </StyledCollapsible>
  );
};

const StyledCollapsible = styled(Collapsible)`
  border-bottom: 3px solid #304f4c;
  margin-bottom: ${toVWMobile(35)}vw;
  padding-bottom: ${toVWMobile(14)}vw;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    margin: 0 ${toVWDesktop(105)}vw ${toVWDesktop(35)}vw;
    padding-bottom: ${toVWDesktop(14)}vw;
  }
`;

const CollapseTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

const CollapseTitle = styled.h3`
  color: #182624;
  font-family: ${props => props.theme.fontFamily}, ${props => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(36)}rem;
  font-weight: 900;
  font-style: normal;
  letter-spacing: 0.03em;
  line-height: ${toREM(40)}rem;
  text-align: left;
  text-transform: uppercase;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    font-size: ${toREM(48)}rem;
    line-height: ${toREM(56)}rem;
  }
`;

export const Arrow = styled(SVG)`
  margin-left: ${toVWMobile(8)}vw;
  width: ${toVWMobile(24)}vw;
  height: ${toVWMobile(13)}vw;
  fill: #162221;
  z-index: 1;
  transition: transform 0.5s;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    margin-left: ${toVWDesktop(8)}vw;
    width: ${toVWDesktop(24)}vw;
    height: ${toVWDesktop(13)}vw;
  }
`;

export default CollapseMenu;
