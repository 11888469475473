import { uniqueId } from "lodash";
import React, { FC, useMemo, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import ReactPlayer from "react-player";
import "slick-carousel/slick/slick.css";
import styled, { keyframes, css } from "styled-components";
import Download from "../../assets/images/download.svg";
import {
  toREM,
  toVWDesktop,
  toVWMobile,
  vwDesktop,
  vwMobile,
} from "../../helpers/styles";
import { BREAKPOINTS, MEDIA_DESKTOP } from "../../helpers/theme";
import { Image } from "../../types";
import BackButton from "../shared/BackButton";
import BMCarousel from "../shared/BMCarousel/BMCarousel";
import ButtonLink, { ButtonType } from "../shared/ButtonLink";
import PlayArrowButton, {
  PlayButtonRef,
} from "../shared/PlayArrowButton/PlayArrowButton";

export type VideoCarouselProps = {
  isPublished?: boolean;
  title: string;
  mediaList: Media[];
  button: { label: string; link: string };
  back?: string;
};

type Media = {
  id: string;
  image?: Image;
  url?: string;
};

const getizeRatio = (text: string) => {
  if (text?.length > 10) {
    return 0.75;
  }
  return 1;
};

const VideoCarousel: FC<VideoCarouselProps> = ({
  isPublished,
  title,
  mediaList,
  button,
  back,
}: VideoCarouselProps) => {
  if (!isPublished) return null;

  const playArrow = useRef<PlayButtonRef>(null);
  const [reverse, setReverse] = useState(false);
  const [activeSlide, setActiveSlide] = useState(0);
  const slides = mediaList;
  const carouselId = useMemo(() => uniqueId("bm-carousel"), []);

  const handleNext = () => {
    setReverse(false);
    setActiveSlide((prev) => {
      return prev === slides?.length - 1 ? 0 : prev + 1;
    });
  };

  const handlePrev = () => {
    setReverse(true);
    setActiveSlide((prev) => {
      return prev === 0 ? slides?.length - 1 : prev - 1;
    });
  };

  return (
    <>
      <BackBlock>
        <BackButton label={back} link="/food-service" />
      </BackBlock>
      <Container>
        <LeftContent>
          <Title ratio={getizeRatio(title)}>{title}</Title>
          <BtnWrapper>
            <DownloadBtn
              type={ButtonType.PRIMARY_LIGHT}
              label={button?.label}
              iconComponent={<Icon src={Download} alt={button?.label} />}
              link={button?.link}
              download
              target="_blank"
            />
          </BtnWrapper>
        </LeftContent>
        <RightContent>
          <CarouselContainer>
            <BMCarousel
              id={carouselId}
              show={activeSlide}
              animationConfig={{
                from: {
                  transform: reverse ? "translateX(-100%)" : "translateX(100%)",
                },
                enter: { transform: "translateX(0%)" },
                leave: { delay: 500 },
              }}
            >
              {mediaList?.map((media) =>
                media?.url ? (
                  <ReactPlayer
                    key={media?.id}
                    width={
                      isMobile
                        ? `${toVWMobile(375)}vw`
                        : `${toVWDesktop(610)}vw`
                    }
                    height={
                      isMobile
                        ? `${toVWMobile(375)}vw`
                        : `${toVWDesktop(551)}vw`
                    }
                    url={media?.url}
                  />
                ) : (
                  <Picture src={media?.image?.src} alt={media?.image?.alt} />
                )
              )}
            </BMCarousel>
          </CarouselContainer>
          <ArrowWrapper>
            <PlayArrowButton
              ref={playArrow}
              onClick={handleNext}
              borderColor={"#1e4320"}
              bgColor={"#75C154"}
              progressColor="white"
              animated
              onAnimationEnd={handleNext}
              aria-controls={carouselId}
            />
            <PlayArrowButton
              borderColor={"#1e4320"}
              bgColor={"#75C154"}
              rotate
              onClick={handlePrev}
            />
          </ArrowWrapper>
        </RightContent>
      </Container>
    </>
  );
};

const BackBlock = styled.div`
  background-color: #033305;
  padding-left: ${toVWMobile(24)}vw;
  padding-block: ${vwMobile(20)};
  padding-right: ${(props) => props.theme.isRTL && vwMobile(24)};

  > a {
    margin: 0;
    direction: ltr;
  }

  ${MEDIA_DESKTOP} {
    padding-left: ${toVWDesktop(205)}vw;
    padding-block: ${vwDesktop(20)};
    padding-right: ${(props) => props.theme.isRTL && vwDesktop(47)};
  }
`;

const slideIn = keyframes`
  from {
    transform: translateX(100%);
    visibility: visible;
    opacity: 0.3;
    z-index: 3;
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

const LeftContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${toVWMobile(81)}vw ${toVWMobile(24)}vw ${toVWMobile(40)}vw;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    align-items: flex-start;
    width: 50%;
    padding: ${(props) =>
      props.theme.isRTL
        ? `${toVWDesktop(93)}vw ${toVWDesktop(205)}vw ${toVWDesktop(
            82
          )}vw ${toVWDesktop(47)}vw`
        : `${toVWDesktop(93)}vw ${toVWDesktop(47)}vw ${toVWDesktop(
            82
          )}vw ${toVWDesktop(205)}vw`};

    .back {
      height: ${vwDesktop(20)};
    }
  }
`;

const Title = styled.h3<{ ratio: number }>`
  color: #ffffff;
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${(props) => props.ratio * toREM(40)}rem;
  font-weight: 900;
  font-style: normal;
  letter-spacing: 0.03em;
  line-height: ${toREM(42)}rem;
  margin-bottom: ${toVWMobile(55)}vw;
  text-transform: uppercase;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    font-size: ${(props) => props.ratio * toREM(56)}rem;
    line-height: ${toREM(50)}rem;
    margin-bottom: ${toVWDesktop(46)}vw;
  }
`;

const Icon = styled.img`
  width: ${toVWMobile(40)}vw;
  height: ${toVWMobile(40)}vw;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    width: ${toVWDesktop(40)}vw;
    height: ${toVWDesktop(40)}vw;
  }
`;

const BtnWrapper = styled.div`
  display: flex;
  width: 100%;
`;

const DownloadBtn = styled(ButtonLink)`
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  height: ${toVWMobile(56)}vw;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    height: ${toVWDesktop(56)}vw;
  }
`;

const CarouselContainer = styled.div`
  height: ${toVWMobile(375)}vw;
  width: ${toVWMobile(375)}vw;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    width: ${toVWDesktop(610)}vw;
    height: ${toVWDesktop(551)}vw;
  }
`;

const RightContent = styled.div`
  position: relative;
  z-index: 5;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    width: 50%;
  }
`;

const Picture = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`;

const ArrowWrapper = styled.div`
  display: flex;
  direction: ltr;
  flex-direction: row-reverse;
  justify-content: space-between;
  position: absolute;
  bottom: ${toVWMobile(-22)}vw;
  right: ${toVWMobile(25)}vw;
  width: ${toVWMobile(110)}vw;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    flex-direction: column;
    direction: inherit;
    height: ${toVWDesktop(115)}vw;
    top: ${toVWDesktop(200)}vw;
    left: ${toVWDesktop(-22)}vw;
    left: ${(props) => (props.theme.isRTL ? 0 : `${toVWDesktop(-22)}vw`)};
    right: ${(props) => (props.theme.isRTL ? `${toVWDesktop(-20)}vw` : 0)};
    width: fit-content;
  }
`;

const StyledReactPlayer = styled(ReactPlayer)``;

const Container = styled.section`
  background-color: #033305;
  display: flex;
  flex-direction: column-reverse;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    flex-direction: row;
    height: ${toVWDesktop(511)}vw;
  }

  & .slick-slide {
    ${Picture}, ${StyledReactPlayer} {
      z-index: 1;
      transition-delay: 1s;
      transition-duration: 1s;
      transition-property: visibility, position;
      visibility: hidden;
      position: relative;
      left: 0;
      top: 0;
    }
  }

  & .slick-slide.slick-current {
    ${Picture}, ${StyledReactPlayer} {
      animation: ${slideIn} 0.5s ease-in 0s;
      z-index: 2;
      position: relative;
      visibility: visible;
    }
  }
`;

export default VideoCarousel;
