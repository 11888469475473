import { graphql } from "gatsby";
import { useIntl } from "../hooks/useIntl";
import React, { useCallback } from "react";
import get from "lodash/get";
import CookItAtHome, { CookItAtHomeProps } from "../components/CookItAtHome";
import Page from "../components/Page";
import QuestionsAndAnswers, {
  QuestionsAndAnswersProps,
} from "../components/QuestionsAndAnswers";
import SellFaqs, { SellFaqsProps } from "../components/SellFaqs";
import { StartSellingFormLabels } from "../components/StartSelling";
import Floater from "../components/StartSelling/Floater";
import VideoCarousel, { VideoCarouselProps } from "../components/VideoCarousel";
import { getAssetURL } from "../helpers/assets";
import { getTranslations } from "../helpers/translations";
import SEO from "../components/SEO";
import { parseSEO } from "../helpers/seo";
import PartnersReviews, {
  PartnersReviewsProps,
} from "../components/PartnersReviews";
import styled from "styled-components";
import { MEDIA_DESKTOP } from "../helpers/theme";
import { toVWDesktop, toVWMobile } from "../helpers/styles";
import BackButton from "../components/shared/BackButton";
import { isPublished } from "../helpers/directus";
import { useStaticLabels } from "../hooks/useStaticLabels";
import { ROUTES } from "../helpers/routes";

const SellDetailPage = ({ data }: { data: any }) => {
  const intl = useIntl();
  const labels = useStaticLabels();

  const getRootTranslatedData = useCallback(() => {
    return getTranslations(
      data,
      "directus.sell_page_by_id.translations",
      intl.locale
    );
  }, [data]);

  const getVideoCarouselData = useCallback((): VideoCarouselProps => {
    const translatedData = getRootTranslatedData();

    const { sell_sheet } =
      getTranslations(
        translatedData,
        "product_detail.translations",
        intl.locale
      ) ?? {};

    const translatedHero = translatedData?.hero_carousel_slides
      ?.map((hero: any) => {
        const slide = getTranslations(
          hero,
          "sell_page_hero_slides_id.translations",
          intl.locale
        );
        return {
          id: slide?.id,
          status: slide?.status,
          image: {
            src: getAssetURL(slide?.image),
            alt: slide?.image_alt,
          },
          url: slide?.video_url,
        };
      })
      ?.filter(({ status }: { status: string }) => isPublished(status));

    return {
      isPublished: isPublished(translatedData?.product_status),
      title: translatedData?.hero_carousel_title,
      back: labels.backToFoodService,
      button: {
        label: labels?.downloadSellSheet,
        link: getAssetURL(sell_sheet),
      },
      mediaList: translatedHero,
    };
  }, [getRootTranslatedData, intl]);

  const getSellFaqsData = useCallback((): SellFaqsProps => {
    const translatedData = getRootTranslatedData();

    const { sell_page_content } =
      getTranslations(
        translatedData,
        "product_detail.translations",
        intl.locale
      ) ?? {};

    const translatedPageContent =
      getTranslations(sell_page_content, "translations", intl.locale) ?? {};

    const translatedBadges = translatedPageContent?.badges
      ?.map((badge: any) => {
        const itemBadge =
          getTranslations(badge, "badges_id.translations", intl.locale) ?? {};
        return {
          status: itemBadge?.status,
          src: getAssetURL(itemBadge?.image),
          alt: itemBadge?.image_alt,
        };
      })
      ?.filter(({ status }: { status: string }) => isPublished(status));

    const translatedMethods = translatedPageContent?.methods
      ?.map((method: any) => {
        const itemBadge =
          getTranslations(
            method,
            "cooking_methods_id.translations",
            intl.locale
          ) ?? {};

        const icons_list = itemBadge?.icons_list
          ?.map((icon: any) => {
            const translatedIcon = getTranslations(
              icon,
              "cooking_methods_icons_id.translations",
              intl.locale
            );
            return {
              status: translatedIcon?.status,
              src: getAssetURL(translatedIcon?.image),
              alt: translatedIcon?.image_alt,
            };
          })
          ?.filter(({ status }: { status: string }) => isPublished(status));

        return { ...itemBadge, logos: icons_list };
      })
      ?.filter(({ status }: { status: string }) => isPublished(status));

    return {
      isPublished: isPublished(translatedPageContent?.status),
      benefitImages: translatedBadges,
      ingredientsTitle: labels?.ingredientsTitle,
      ingredientsDetail: translatedPageContent?.ingredients_detail,
      factsTitle: labels?.nutritionsFactsTitle,
      facts: translatedPageContent?.nutritions_facts_details,
      caption: translatedPageContent?.nutritions_facts_note,
      factsTable: {
        header: [
          get(translatedPageContent, "nutritions_facts_data[0].title"),
          get(translatedPageContent, "nutritions_facts_data[0].amount"),
          get(translatedPageContent, "nutritions_facts_data[0].daily_value"),
        ],
        body: (translatedPageContent?.nutritions_facts_data || [])
          .filter((e, i) => i > 0)
          .reduce((results: any, fact: any) => {
            if (fact.title) {
              results.push({
                label: fact?.title,
                amount: fact?.amount,
                percentage: fact?.daily_value,
              });
            }
            return results;
          }, []),
      },
      instructionsTitle: labels?.cookingInstructionsTitle,
      instructions: translatedMethods,
    };
  }, [getRootTranslatedData, intl]);

  const getCookItAtHomeData = useCallback((): CookItAtHomeProps => {
    const translatedData = getRootTranslatedData();

    return {
      title: translatedData?.ideas_for_your_menu_title,
      cards: isPublished(translatedData?.ideas_for_your_menu_status)
        ? translatedData?.ideas_for_your_menu_recipes
            ?.map((card: any) => {
              const { status, recipe = [] } =
                getTranslations(
                  card,
                  "recipe_page_id.translations",
                  intl.locale
                ) || {};

              const { title, image, image_alt } =
                getTranslations(recipe, "translations", intl.locale) || {};

              const { product } =
                getTranslations(
                  recipe,
                  "product_page.translations",
                  intl.locale
                ) || {};

              const { product_name } =
                getTranslations(product, "translations", intl.locale) || {};

              return {
                status: status,
                id: card?.recipe_page_id?.id,
                link: `${ROUTES.RECIPE_DETAIL}/${card?.recipe_page_id?.recipe_page_slug}`,
                title: product_name,
                description: title,
                image: { src: getAssetURL(image), alt: image_alt },
              };
            })
            ?.filter(({ status }: { status: string }) => isPublished(status))
        : [],
    };
  }, [getRootTranslatedData, intl]);

  const getQuestionsData = useCallback((): QuestionsAndAnswersProps => {
    const translatedData = getRootTranslatedData();
    if (!isPublished(translatedData?.faq_status)) return null;

    return {
      title: translatedData?.faq_title,
      subtitle: translatedData?.faq_subtitle,
      button: {
        label: labels?.viewAllFaqs,
        link: ROUTES.FAQS,
      },
      cards: translatedData?.faq_cards?.map(
        (card: { title: string; description: string }) => ({
          title: card.title,
          description: card.description,
        })
      ),
    };
  }, [getRootTranslatedData, intl]);

  const getFormData = useCallback((): {
    formProps: StartSellingFormLabels;
    heading: string;
  } => {
    const { lets_talk_form } =
      getTranslations(
        data,
        "directus.sell_landing_page.translations",
        intl.locale
      ) ?? {};

    const partnerForm =
      getTranslations(lets_talk_form, "translations", intl.locale) ?? {};

    return {
      formProps: {
        title: partnerForm?.title,
        description: partnerForm?.description,
        successMessage: partnerForm?.success_message,
        klaviyoListId: partnerForm?.klaviyo_id,
        first_step: {
          tab_form_title: partnerForm?.left_tab_title,
          country_placeholder: labels?.countryPlaceholder,
          first_name_placeholder: labels?.firstNamePlaceholder,
          last_name_placeholder: labels?.lastNamePlaceholder,
          business_name_placeholder: labels?.businessNamePlaceholder,
          city_placeholder: labels?.cityPlaceholder,
          state_province_placeholder: labels?.stateProvincePlaceholder,
          button_link: "",
          button_label: partnerForm?.left_tab_button_label,
        },
        second_step: {
          tab_form_title: partnerForm?.right_tab_title,
          email_placeholder: labels?.emailPlaceholder,
          phone_placeholder: labels?.phonePlaceholder,
          which_product_placeholder: partnerForm?.which_product_placeholder,
          which_distributors_placeholder:
            partnerForm?.which_distributors_placeholder,
          disclaimer: partnerForm?.disclaimer,
          left_button_label: partnerForm?.right_tab_secondary_button_label,
          left_button_link: partnerForm?.right_tab_secondary_button_link,
          right_button_label: partnerForm?.right_tab_primary_button_label,
          right_button_link: partnerForm?.right_tab_primary_button_link,
        },
        successMessage: partnerForm?.success_message,
        klaviyoListId: partnerForm?.klaviyo_id,
      },
      heading: partnerForm?.form_label,
    };
  }, [data, intl]);

  const getLetsTalkBeyondData = useCallback((): PartnersReviewsProps => {
    const t = getRootTranslatedData();

    return {
      title: t?.reviews_title,
      cardList: isPublished(t?.reviews_status)
        ? t?.reviews_partners_opinion?.map((review: any) => ({
            quote: review?.partner_quote,
            name: review?.partner_name,
            job_role: review?.partner_job_role,
          }))
        : [],
    };
  }, [getRootTranslatedData, intl]);

  return (
    <Page
      headerBackgroundColor={"#033305"}
      helmet={<SEO {...parseSEO(getRootTranslatedData(), intl?.locale)} />}
    >
      <VideoCarousel {...getVideoCarouselData()} />
      <SellFaqs {...getSellFaqsData()} />
      <CookItAtHome {...getCookItAtHomeData()} />
      <PartnersReviews {...getLetsTalkBeyondData()} />
      <QuestionsAndAnswers {...getQuestionsData()} />
      <Floater {...getFormData()} collapseLabel="Collapse" />
    </Page>
  );
};

export default SellDetailPage;

export const query = graphql`
  query ($id: ID!, $locale: String) {
    directus {
      sell_page_by_id(id: $id) {
        sell_page_slug
        translations(filter: { languages_code: { code: { _eq: $locale } } }) {
          seo_information {
            ...SEO
          }
          languages_code {
            code
            name
          }
          status
          hero_carousel_status
          hero_carousel_title
          hero_carousel_slides {
            sell_page_hero_slides_id {
              translations(
                filter: { languages_code: { code: { _eq: $locale } } }
              ) {
                languages_code {
                  code
                  name
                }
                id
                status
                image_alt
                video_url
                image {
                  id
                  filename_disk
                }
              }
            }
          }
          product_status
          product_detail {
            translations(
              filter: { languages_code: { code: { _eq: $locale } } }
            ) {
              languages_code {
                code
                name
              }
              status
              product_name
              sell_sheet {
                id
                filename_disk
              }
              description
              variants {
                product_variant_id {
                  variant_slug
                  translations(
                    filter: { languages_code: { code: { _eq: $locale } } }
                  ) {
                    languages_code {
                      code
                      name
                    }
                    id
                    status
                    title
                    subtitle
                    description
                    large_image_alt
                    large_image {
                      id
                      filename_disk
                    }
                  }
                }
              }
              sell_page_content {
                translations(
                  filter: { languages_code: { code: { _eq: $locale } } }
                ) {
                  languages_code {
                    code
                    name
                  }
                  status
                  badges {
                    badges_id {
                      translations(
                        filter: { languages_code: { code: { _eq: $locale } } }
                      ) {
                        languages_code {
                          code
                          name
                        }
                        status
                        image_alt
                        image {
                          id
                          filename_disk
                        }
                      }
                    }
                  }
                  ingredients_detail
                  nutritions_facts_note
                  nutritions_facts_details
                  nutritions_facts_data
                  methods {
                    cooking_methods_id {
                      translations(
                        filter: { languages_code: { code: { _eq: $locale } } }
                      ) {
                        languages_code {
                          code
                          name
                        }
                        status
                        title
                        description
                        icons_list {
                          cooking_methods_icons_id {
                            translations(
                              filter: {
                                languages_code: { code: { _eq: $locale } }
                              }
                            ) {
                              languages_code {
                                code
                                name
                              }
                              status
                              image_alt
                              image {
                                id
                                filename_disk
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          ideas_for_your_menu_status
          ideas_for_your_menu_title
          ideas_for_your_menu_recipes {
            recipe_page_id {
              recipe_page_slug
              translations(
                filter: { languages_code: { code: { _eq: $locale } } }
              ) {
                languages_code {
                  code
                  name
                }
                status
                recipe_content_status
                recipe {
                  product_page {
                    id
                    product_page_slug
                    translations(
                      filter: { languages_code: { code: { _eq: $locale } } }
                    ) {
                      languages_code {
                        code
                        name
                      }
                      product {
                        translations(
                          filter: { languages_code: { code: { _eq: $locale } } }
                        ) {
                          languages_code {
                            code
                            name
                          }
                          product_name
                        }
                      }
                    }
                  }
                  translations(
                    filter: { languages_code: { code: { _eq: $locale } } }
                  ) {
                    languages_code {
                      code
                      name
                    }
                    title
                    image {
                      id
                      filename_disk
                    }
                    image_alt
                  }
                }
              }
            }
          }
          reviews_status
          reviews_title
          reviews_partners_opinion
          faq_status
          faq_title
          faq_subtitle
          faq_cards
        }
      }

      sell_landing_page {
        translations(filter: { languages_code: { code: { _eq: $locale } } }) {
          languages_code {
            code
            name
          }
          lets_talk_form {
            translations(
              filter: { languages_code: { code: { _eq: $locale } } }
            ) {
              languages_code {
                code
                name
              }
              form_label
              title
              klaviyo_id
              description
              success_message
              left_tab_title
              left_tab_button_label
              right_tab_title
              disclaimer
              which_product_placeholder
              which_distributors_placeholder
              right_tab_primary_button_label
              right_tab_primary_button_link
              right_tab_secondary_button_label
              right_tab_secondary_button_link
            }
          }
        }
      }
    }
  }
`;
