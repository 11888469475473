import React, { ReactNode, useMemo } from "react";
import {
  animated,
  useSpringRef,
  useTransition,
  UseTransitionProps,
} from "react-spring";
import styled from "styled-components";

export type BMCarouselProps = {
  show?: number;
  animationConfig: UseTransitionProps;
} & React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

export const BMCarousel = (props: BMCarouselProps) => {
  const { show = 0, animationConfig, children, ...rest } = props;

  const transRef = useSpringRef();
  const slideTransition = useTransition(show, {
    ref: transRef,
    keys: show,
    ...animationConfig,
  });

  const items: ReactNode[] | ReactNode = useMemo(
    () => React.Children.map(children, (child) => child) ?? [],
    [children]
  );

  return (
    <Container aria-live="polite" aria-roledescription="carousel" {...rest}>
      {slideTransition((animatedStyle, i: number) => {
        const item = items[i] ?? null;
        return (
          <animated.div aria-roledescription="slide" style={animatedStyle}>
            {item}
          </animated.div>
        );
      })}
    </Container>
  );
};

const Container = styled.div`
  overflow: hidden;
  position: relative;
  height: 100%;
  width: 100%;

  > div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

export default BMCarousel;
