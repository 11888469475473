import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";

import { toVWMobile, toVWDesktop, toREM } from "../../helpers/styles";
import { BREAKPOINTS } from "../../helpers/theme";
import { Image } from "../../types";
import Link from "../shared/Link";

export type CardType = {
  id: string;
  link: string;
  image: Image;
  title: string;
  description: string;
  withOffset: boolean;
  className: string;
  onHover: (hovered: boolean) => void;
};

const Card: FC<CardType> = ({
  id,
  link,
  image,
  title,
  description,
  withOffset,
  className,
  onHover,
}: CardType) => {
  const [isHovered, setHovered] = useState(false);

  useEffect(() => {
    onHover(isHovered);
  }, [isHovered]);

  return (
    <CardContainer
      className={`${className} ${withOffset ? "with-card-offset" : ""}`}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      key={id}
    >
      <Picture src={image?.src} alt={image?.alt} />
      <TextWrapper to={link}>
        <SmallText>{title}</SmallText>
        <BigText>{description}</BigText>
      </TextWrapper>
    </CardContainer>
  );
};

const Picture = styled.img`
  object-fit: cover;
  width: ${toVWMobile(317)}vw;
  height: ${toVWMobile(317)}vw;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    width: ${toVWDesktop(400)}vw;
    height: ${toVWDesktop(400)}vw;
  }
`;

const TextWrapper = styled(Link)`
  background-color: #544423;
  padding: ${toVWMobile(24)}vw ${toVWMobile(20)}vw ${toVWMobile(24)}vw;
  position: relative;
  transition: transform 0.5s;
  text-decoration: none;
  height: ${toVWMobile(172)}vw;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    padding: ${toVWDesktop(20)}vw ${toVWDesktop(28)}vw ${toVWDesktop(32)}vw;
    height: ${toVWDesktop(216)}vw;
  }
`;

const SmallText = styled.h4`
  color: #ccb983;
  font-family: ${props => props.theme.fontFamily}, ${props => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(18)}rem;
  line-height: ${toREM(24)}rem;
  font-weight: 800;
  font-style: normal;
  letter-spacing: 0.08em;
  margin-bottom: ${toVWMobile(7)}vw;
  text-align: left;
  text-transform: uppercase;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    margin-bottom: ${toVWDesktop(7)}vw;
  }
`;

const BigText = styled.p`
  color: #ffffff;
  font-family: ${props => props.theme.fontFamily}, ${props => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(24)}rem;
  line-height: ${toREM(31)}rem;
  font-weight: 900;
  letter-spacing: 0.06em;
  text-align: left;
  text-transform: uppercase;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    font-size: ${toREM(36)}rem;
    line-height: ${toREM(40)}rem;
    font-weight: 900;
    letter-spacing: 0.03em;
  }
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  outline: none;
  position: relative;
  text-decoration: none;
  width: ${toVWMobile(317)}vw;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
  }

  &.with-card-offset {
    position: relative;
    bottom: ${toVWMobile(-40)}vw;

    @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
      bottom: ${toVWDesktop(-40)}vw;
    }
  }

  &:before {
    content: "";
    background-color: #544423;
    bottom: 0;
    left: 0;
    height: ${toVWMobile(100)}vw;
    position: absolute;
    width: 100%;
    @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
      height: ${toVWDesktop(100)}vw;
    }
  }

  &:hover {
    cursor: pointer;

    ${TextWrapper} {
      background-color: #033305;
      transform: translateY(${toVWMobile(-50)}vw);
      @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
        transform: translateY(${toVWDesktop(-50)}vw);
      }
    }

    ${BigText} {
      color: #ccb983;
      text-decoration: underline;
    }

    :before {
      background-color: #033305;
    }
  }

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    min-width: ${toVWDesktop(400)}vw;
    width: ${toVWDesktop(400)}vw !important;
  }
`;

export default Card;
