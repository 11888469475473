import React, { FC } from "react";
import styled from "styled-components";

import CollapseMenu, { Arrow } from "./CollapseMenu";

import { Image } from "../../types";
import {
  toVWMobile,
  toVWDesktop,
  toREM,
  vwDesktop,
  vwMobile,
} from "../../helpers/styles";
import { BREAKPOINTS, MEDIA_DESKTOP } from "../../helpers/theme";

export type SellFaqsProps = {
  isPublished?: boolean;
  benefitImages?: Image[];
  ingredientsTitle?: string;
  ingredientsDetail?: string;
  description?: string;
  factsTitle?: string;
  facts?: string;
  factsTable?: FactsTable;
  caption?: string;
  instructionsTitle?: string;
  instructions?: Instruction[];
};

type FactsTable = {
  header: string[];
  body: SimpleFact[];
};

type SimpleFact = {
  label?: string;
  amount?: string;
  percentage?: string;
};

type Instruction = {
  logos?: Image[];
  title?: string;
  description?: string;
};

const SellFaqs: FC<SellFaqsProps> = ({
  isPublished,
  benefitImages,
  ingredientsTitle,
  ingredientsDetail,
  factsTitle,
  facts,
  factsTable,
  caption,
  instructionsTitle,
  instructions,
}: SellFaqsProps) => {
  if (
    (!ingredientsTitle &&
      !ingredientsDetail &&
      !factsTitle &&
      !facts &&
      !factsTable &&
      !instructionsTitle &&
      !instructions) ||
    !isPublished
  ) {
    return null;
  }

  const isDailyValue = factsTable?.body?.some((daily) => daily?.percentage);

  return (
    <Container>
      <IconsContainer>
        {benefitImages?.map((benefit, index) => (
          <Icon key={index} src={benefit?.src} alt={benefit?.alt} />
        ))}
      </IconsContainer>
      <CollapseMenu title={ingredientsTitle}>
        <Description
          dangerouslySetInnerHTML={{
            __html: ingredientsDetail,
          }}
        />
      </CollapseMenu>
      <CollapseMenu title={factsTitle}>
        <>
          <FactWrapper
            dangerouslySetInnerHTML={{
              __html: facts,
            }}
          />
          <Table>
            <TableRow>
              {factsTable?.header?.map((header, index) => {
                if (index === 0) {
                  return <TableDataLabel key={index} />;
                }
                if (index === 2 && !isDailyValue) return null;
                if (header) {
                  return (
                    <TableHeader
                      aria-label={header}
                      key={index}
                      dangerouslySetInnerHTML={{
                        __html: header,
                      }}
                    />
                  );
                }
                return <TableDataLabel key={index} />;
              })}
            </TableRow>
            {factsTable?.body?.map((row, index) => (
              <TableRow key={index}>
                <TableDataLabel
                  dangerouslySetInnerHTML={{
                    __html: row?.label,
                  }}
                />
                <TableData 
                  dangerouslySetInnerHTML={{
                    __html: row?.amount,
                  }}
                />
                {row?.percentage && (<TableData
                  dangerouslySetInnerHTML={{
                    __html: row?.percentage,
                  }}
                />)}
              </TableRow>
            ))}
          </Table>
          <Description className="nutrition">{caption}</Description>
        </>
      </CollapseMenu>
      <CollapseMenu title={instructionsTitle}>
        {instructions?.map((instruction, index) => (
          <InstructionCard key={index}>
            <InstructionLogoWrapper>
              {instruction.logos?.map((logo, indexLogo) => (
                <InstructionImage
                  key={indexLogo}
                  src={logo?.src}
                  alt={logo?.alt}
                />
              ))}
            </InstructionLogoWrapper>
            <InstructionTitle>{instruction.title}</InstructionTitle>
            <Description
              className="instruction"
              dangerouslySetInnerHTML={{ __html: instruction?.description }}
            />
          </InstructionCard>
        ))}
      </CollapseMenu>
    </Container>
  );
};

const IconsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 0 ${toVWMobile(10)}vw ${toVWMobile(61)}vw;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    justify-content: space-between;
    padding: 0;
    margin-bottom: ${toVWDesktop(80)}vw;
  }
`;

const Icon = styled.img`
  height: ${toVWMobile(98)}vw;
  margin-bottom: ${toVWMobile(19)}vw;
  width: auto;
  object-fit: contain;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    height: ${toVWDesktop(129)}vw;
    margin-bottom: 0;
    width: auto;
  }
`;

const Description = styled.p`
  color: #162221;
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(18)}rem;
  font-weight: 600;
  font-style: normal;
  letter-spacing: normal;
  line-height: ${toREM(30)}rem;
  margin: ${toVWMobile(35)}vw 0 ${toVWMobile(25)}vw;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    margin: ${toVWDesktop(30)}vw 0 ${toVWDesktop(60)}vw;
  }

  & ul {
    padding: 0 ${toVWMobile(20)}vw;

    @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
      padding: 0 ${toVWDesktop(25)}vw;
    }
  }
`;

const FactWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  color: #162221;
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(18)}rem;
  font-style: normal;
  letter-spacing: normal;
  line-height: ${toVWMobile(28)}vw;
  margin-top: ${toVWMobile(35)}vw;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    line-height: ${toVWDesktop(28)}vw;
    margin-top: ${toVWDesktop(15)}vw;
  }
`;

const Table = styled.table`
  border-collapse: collapse;
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  width: 100%;
`;

const TableRow = styled.tr`
  color: #182624;
  border-bottom: ${toVWMobile(1)}vw solid #3a6249;
`;

const TableHeader = styled.th`
  color: #162221;
  font-size: ${toREM(14)}rem;
  font-weight: 600;
  text-align: ${(props) => (props.theme.isRTL ? "left" : "right")};
  padding: ${toVWMobile(15)}vw 0;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    padding: ${toVWDesktop(15)}vw 0;
  }
`;

const TableData = styled.td`
  color: #162221;
  text-align: ${(props) => (props.theme.isRTL ? "left" : "right")};
  font-size: ${toREM(18)}rem;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0.00018em;
  padding: ${toVWMobile(15)}vw 0;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    padding: ${toVWDesktop(15)}vw 0;
  }
`;

const TableDataLabel = styled(TableData)`
  text-align: ${(props) => (props.theme.isRTL ? "right" : "left")};
  min-width: ${toVWMobile(120)}vw;
  max-width: ${toVWMobile(120)}vw;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    min-width: ${toVWDesktop(120)}vw;
    max-width: ${toVWDesktop(120)}vw;
  }
`;

const InstructionCard = styled.div`
  margin-top: ${toVWMobile(35)}vw;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    margin-top: ${toVWDesktop(15)}vw;
  }
`;

const InstructionLogoWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${vwMobile(20)};
  ${MEDIA_DESKTOP} {
    gap: ${vwDesktop(20)};
  }
`;

const InstructionImage = styled.img`
  height: ${toVWMobile(72)}vw;
  width: ${toVWMobile(72)}vw;
  margin-bottom: ${toVWMobile(26)}vw;
  object-fit: contain;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    height: ${toVWDesktop(72)}vw;
    width: ${toVWDesktop(72)}vw;
    margin-bottom: ${toVWDesktop(26)}vw;
  }
`;

const InstructionTitle = styled.h4`
  color: #182624;
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(24)}rem;
  font-weight: 900;
  font-style: normal;
  letter-spacing: ${toREM(0.72)}rem;
  line-height: ${toVWMobile(40)}vw;
  margin-bottom: ${toVWMobile(17)}vw;
  text-transform: uppercase;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    letter-spacing: normal;
    font-size: ${toREM(36)}rem;
    line-height: ${toVWDesktop(40)}vw;
    margin-bottom: ${toVWDesktop(17)}vw;
  }
`;

const Container = styled.section`
  background-color: #678e8b;
  padding: ${toVWMobile(48)}vw ${toVWMobile(20)}vw ${toVWMobile(40)}vw;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    padding: ${toVWDesktop(84)}vw ${toVWDesktop(205)}vw ${toVWDesktop(80)}vw;
  }

  & .Collapsible {
    border-bottom: 3px solid #304f4c;
    margin-bottom: ${toVWMobile(35)}vw;
    padding-bottom: ${toVWMobile(14)}vw;

    @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
      margin: 0 ${toVWDesktop(105)}vw ${toVWDesktop(35)}vw;
      padding-bottom: ${toVWDesktop(14)}vw;
    }

    .is-closed ${Arrow} {
      transform: rotate(180deg);
    }
  }
`;

export default SellFaqs;
